import React from 'react';
import { Logo as LogoCtm } from '@agria/theme/src/components/svg/svgs/Logo';
import { CtmLogo } from '@agria/theme/src/components/svg/svgs/CtmLogo';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledLogos = styled.div`
  ${({ theme: { space } }) => css`
    align-items: center;
    display: flex;
    gap: ${space.fluidMedium};

    > svg {
      flex: 0 0 auto;

      &:last-of-type {
        border-left: 0.5px solid #cde6e0;
        padding: 0 0 0 ${space.fluidMedium};
      }
    }
  `};
`;

const Logo = () => (
  <StyledLogos>
    <LogoCtm />
    <CtmLogo />
  </StyledLogos>
);

export { Logo };
