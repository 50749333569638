import React, { FC } from 'react';

// Interface

export interface LogoAmexProps {}

export const LogoAmex: FC<LogoAmexProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 46 32">
    <path
      fill="#1976D2"
      d="M44.7598 27.4583c0 2.3011-1.8657 4.1667-4.1667 4.1667H5.17643c-2.30104 0-4.16666-1.8656-4.16666-4.1667V4.54167C1.00977 2.24062 2.87539.375 5.17643.375H40.5931c2.301 0 4.1667 1.86562 4.1667 4.16667V27.4583Z"
    />
    <path
      fill="#fff"
      d="m21.0669 11.8334-2.201 4.8781-2.1907-4.8781h-2.8073v7.0062l-3.1281-7.0062H8.37106l-3.19479 7.2718h1.89062l.69896-1.6229h3.57505l.7104 1.6229h3.6093v-5.401l2.3948 5.401h1.6282l2.4489-5.3072v5.3072h1.7938v-7.2718h-2.8594ZM8.44189 15.9115l1.06875-2.5417 1.11046 2.5417H8.44189Zm28.90421-.5229 3.2468-3.5365h-2.3093l-2.0771 2.2542-2.0094-2.2729h-7.1458v7.2729h6.9114l2.1792-2.4073 2.125 2.426h2.3021l-3.2229-3.7364Zm-4.3282 2.1906h-4.1781v-1.4385h3.9979v-1.3782h-3.9979V13.4l4.4104.0125 1.7636 1.9761-1.9959 2.1906Z"
    />
  </svg>
);
