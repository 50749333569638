import React from 'react';
import { LogoSmall as LogoSmallCtm } from '@agria/theme/src/components/svg/svgs/LogoSmall';
import { CtmLogoSmall } from '@agria/theme/src/components/svg/svgs/CtmLogoSmall';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const StyledLogos = styled.div`
  ${({ theme: { space } }) => css`
    align-items: center;
    display: flex;
    gap: ${space.fluidXSmall};

    > svg {
      flex: 0 0 auto;

      &:last-of-type {
        border-left: 0.5px solid #cde6e0;
        padding: 0 0 0 ${space.fluidXSmall};
      }
    }
  `};
`;

const LogoSmall = () => (
  <StyledLogos>
    <LogoSmallCtm />
    <CtmLogoSmall />
  </StyledLogos>
);

export { LogoSmall };
